import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
`;

const ResponsiveColumns = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
`;

const LeftColumn = styled.div`
  flex: 0.5; /* Make the left column smaller */
  min-width: 150px; /* Set a smaller minimum width for the left column */
`;

const ButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const RightColumn = styled.div`
  flex: 1; /* Keep the right column larger */
  min-width: 200px;
`;

const ConvertButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
`;

const ClearButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #d32f2f;
  }
`;

const CopyButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #45a049;
  }

  &.copied {
    background-color: #388e3c;
  }
`;

const ConvertEnvToEcosystemScript = () => {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [copyStatus, setCopyStatus] = useState('Copy to Clipboard'); // Initial button text

  // Function to convert the input text into the Node.js/PM2 ecosystem format
  const handleConvertToScript = () => {
    try {
      const lines = inputText.split('\n'); // Split input by new lines
      const envVariables = lines
        .filter(line => line.includes('=')) // Filter valid key=value pairs
        .map(line => {
          const [key] = line.split('=');
          return `\t\t${key.trim()}: process.env.${key.trim()}`;
        });

      // Join the env variables without a trailing comma on the last one
      const envString = envVariables.join(',\n');

      const script = `
require("dotenv").config();
module.exports = {
  apps: [
    {
      name: 'your-application-name',
      cwd: 'your-application-path. take it by command : pwd',
      script: 'npm',
      args: 'start',
      env: {
${envString}
      },
    },
  ],
};`;

      setOutputText(script.trim()); // Set the formatted script
      setCharCount(inputText.length);
    } catch (error) {
      setOutputText('Error converting text to script');
    }
  };

  const handleClear = () => {
    setInputText('');
    setOutputText('');
    setCharCount(0);
    setCopyStatus('Copy to Clipboard'); // Reset button text
  };

  const handleCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(outputText)
        .then(() => {
          setCopyStatus('Copied Successfully'); // Update button text on success
          setTimeout(() => setCopyStatus('Copy to Clipboard'), 2000); // Reset button text after 2 seconds
        })
        .catch(() => setCopyStatus('Failed to Copy')); // Update button text on failure
    } else {
      // Fallback
      const textarea = document.createElement('textarea');
      textarea.value = outputText;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        const successful = document.execCommand('copy');
        if (successful) {
          setCopyStatus('Copied Successfully');
          setTimeout(() => setCopyStatus('Copy to Clipboard'), 2000);
        } else {
          setCopyStatus('Failed to Copy');
        }
      } catch {
        setCopyStatus('Failed to Copy');
      }
      document.body.removeChild(textarea);
    }
  };

  return (
    <Container>
      <h2>Convert Env to PM2 Ecosystem Script</h2>
      <ResponsiveColumns>
        {/* Left Column for Input */}
        <LeftColumn>
          <label>Enter Text (e.g., key=value):</label>
          <textarea
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            rows="10"
            style={{ width: '100%' }}
          />
        </LeftColumn>

        {/* Button Column */}
        <ButtonColumn>
          <ConvertButton onClick={handleConvertToScript}>
            Convert to Script
          </ConvertButton>
          <ClearButton onClick={handleClear}>
            Clear
          </ClearButton>
        </ButtonColumn>

        {/* Right Column for Output */}
        <RightColumn>
          <label>Converted Script (ecosystem.config.js):</label>
          <textarea
            value={outputText}
            readOnly
            rows="10"
            style={{ width: '100%' }}
          />
          <CopyButton className={copyStatus === 'Copied Successfully' ? 'copied' : ''} onClick={handleCopy}>
            {copyStatus}
          </CopyButton>
        </RightColumn>
      </ResponsiveColumns>
      <p>Total Characters: {charCount}</p>
    </Container>
  );
};

export default ConvertEnvToEcosystemScript;
