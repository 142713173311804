import React from 'react';
import UnderConstruction from '../components/UnderConstruction';
const Home = () => {
  return (
    <div>
      <UnderConstruction /> {/* Display the under construction banner */}
    </div>
  );
};

export default Home;
