import React from 'react';

const Experience = () => {
  return (
    <section>
      <h2>Work Experience</h2>
      <div>
        <h3>DevOps Engineer at XYZ Corp</h3>
        <p>Jan 2022 - Present</p>
        <p>Responsibilities include managing CI/CD pipelines, deploying applications on AWS, and maintaining Kubernetes clusters.</p>
      </div>
      <div>
        <h3>Cloud Engineer at ABC Tech</h3>
        <p>Jul 2020 - Dec 2021</p>
        <p>Worked on cloud migration projects, automated infrastructure deployment using Terraform, and improved system performance.</p>
      </div>
    </section>
  );
};

export default Experience;
