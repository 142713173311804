import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const headerStyle = {
    backgroundColor: '#282c34',
    padding: '10px 20px',
    color: 'white',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  const h1LinkStyle = {
    color: 'white',
    textDecoration: 'none',
  };

  const navStyle = {
    display: 'flex',
    alignItems: 'center',
  };

  const linkStyle = {
    margin: '0 15px',
    color: 'white',
    textDecoration: 'none',
    fontSize: '18px',
    position: 'relative',
  };

  const lastLinkStyle = {
    ...linkStyle,
    marginRight: '0px', // Align last menu item to the right
  };

  const dropdownStyle = {
    display: dropdownOpen ? 'block' : 'none',
    position: 'absolute',
    backgroundColor: '#333',
    color: 'white',
    padding: '10px',
    top: '100%',
    right: '0',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
    whiteSpace: 'nowrap',
  };

  const dropdownLinkStyle = {
    color: 'white',
    textDecoration: 'none',
    display: 'block',
    padding: '8px 16px',
    transition: 'background-color 0.3s',
  };



  return (
    <header style={headerStyle}>
      <Link to="/" style={h1LinkStyle}>
        <h1>Abhiram Das</h1>
      </Link>
      <nav style={navStyle}>
         {/*< Link to="/" style={linkStyle}>Home</Link>
        <Link to="/about" style={linkStyle}>About</Link>
        <Link to="/projects" style={linkStyle}>Projects</Link>
        <Link to="/experience" style={lastLinkStyle}>Experience</Link>*/}

        {/* Dropdown Menu for MyTools */}
        <div ref={dropdownRef} style={{ position: 'relative', display: 'inline-block' }}>
          <span
            style={{ ...linkStyle, cursor: 'pointer' }}
            onClick={toggleDropdown}
          >
            MyTools ▼
          </span>

          <div style={dropdownStyle}>
            <Link
              to="/MyTools/ConvertEnvToEcosystemScript"
              style={dropdownLinkStyle}
              onMouseEnter={(e) => e.target.style.backgroundColor = '#555'}
              onMouseLeave={(e) => e.target.style.backgroundColor = ''}
            >
              Convert Env to PM2 Ecosystem Script
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
