import React from 'react';
import  './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Tool01 from './pages/Tools/ConvertEnvToEcosystemScript';
import Projects from './components/Projects';
import Experience from './components/Experience';
const App = () => {
  return (
    <Router>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/experience" element={<Experience />} />
          <Route path="/MyTools/ConvertEnvToEcosystemScript" element={<Tool01 />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
};

export default App;
