// src/components/Footer.js
import React from 'react';

const Footer = () => {
  const footerStyle = {
    backgroundColor: '#282c34',
    padding: '10px 20px',
    color: 'white',
    textAlign: 'center',
    position: 'fixed',
    width: '100%',
    bottom: '0',
  };

  return (
    <footer style={footerStyle}>
      <p>&copy; 2024 Abhiram Das - DevOps Engineer. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
