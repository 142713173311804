import React from 'react';

const About = () => {
  return (
    <div>
      <h2>About Me</h2>
      <p>I am a DevOps engineer with expertise in AWS, Azure, Docker, Kubernetes, and CI/CD pipelines.</p>
    </div>
  );
};

export default About;
