// src/components/UnderConstruction.js
import React from 'react';

const UnderConstruction = () => {
  const bannerStyle = {
    backgroundColor: '#ffcc00',
    color: '#333',
    textAlign: 'center',
    padding: '50px 0',
    position: 'relative',
    width: '100%',
    marginBottom: '20px',
  };

  const headingStyle = {
    fontSize: '36px',
    margin: '0',
  };

  const paragraphStyle = {
    fontSize: '18px',
    margin: '10px 0 0',
  };

  return (
    <div style={bannerStyle}>
      <h1 style={headingStyle}>Page Under Construction</h1>
      <p style={paragraphStyle}>
        We are working hard to improve this page. Please check back soon!
      </p>
    </div>
  );
};

export default UnderConstruction;
