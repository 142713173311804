import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
    color: #333;
  }

  header {
    background-color: #007acc;
    color: white;
    padding: 10px 0;
    text-align: center;
  }

  footer {
    background-color: #007acc;
    color: white;
    text-align: center;
    padding: 10px 0;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  main {
    padding: 20px;
    min-height: calc(100vh - 160px);
  }

  h2 {
    color: #007acc;
  }

  a {
    color: #007acc;
    text-decoration: none;
  }

  ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  display: inline;
  margin-right: 20px;
}

ul li a {
  text-decoration: none;
  color: #007acc;
  font-weight: bold;
}

ul li a:hover {
  text-decoration: underline;
}
`;

export default GlobalStyle;

