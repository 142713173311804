import React from 'react';

const Projects = () => {
  return (
    <section>
      <h2>Projects</h2>
      <div>
        <h3>Project A</h3>
        <p>A CI/CD pipeline for microservices deployed on AWS using ECS Fargate and CodeDeploy.</p>
        <a href="https://github.com/username/project-a" target="_blank" rel="noopener noreferrer">GitHub Link</a>
      </div>
      <div>
        <h3>Project B</h3>
        <p>A full-stack application deployed on Azure using App Service and Azure DevOps pipelines.</p>
        <a href="https://github.com/username/project-b" target="_blank" rel="noopener noreferrer">GitHub Link</a>
      </div>
    </section>
  );
};

export default Projects;
